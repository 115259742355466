/* Основные стили */
.motorcycles {
  max-width: 100%;
  padding: 20px 0;
  background: rgba(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.motorcycles-container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
}

.motorcycle-cards {
  display: flex;
  gap: 20px;
}

.motorcycle-card {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  max-width: 200px;
  height: 250px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.motorcycle-image {
  border-radius: 4px;
  height: 150px;
  width: 200px;
}

.motorcycle-title {
  font-size: 16px;
  margin: 16px 0;
  font-weight: bold;
}

.motorcycle-description {
  margin: 16px 0;
  font-size: 12px;
  font-weight: 600;
}

.motorcycles-header {
  font-size: 36px;
  font-weight: bold;
  color: #000;
  padding: 10px 20px;
  border-radius: 5px;
}

/* Кнопки навигации */
.arrow-button {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 36px;
  cursor: pointer;
  transition: color 0.3s;
  position: absolute;
  top: 50%;
  
}

.arrow-button:hover {
  color: #002d5c;
}

.arrow-button:first-of-type {
  left: -50px; 
}

.arrow-button:last-of-type {
  right: -50px; 
}
/* Медиазапрос для экранов шириной менее 768px */
@media (max-width: 768px) {
  .motorcycles-container {
    flex-direction: column;
  }

  .motorcycle-cards {
    flex-direction: column;
    gap: 10px; /* Adjust gap for better mobile view */
  }

  .motorcycle-card {
    max-width: 90%;
    height: auto; /* Adjust height for mobile view */
  }
  /* Перемещаем кнопки внутрь области видимости на мобильных устройствах */
  .arrow-button:first-of-type {
    right: 100%;
    
  }

  .arrow-button:last-of-type {
    left: 100%;
    
  }
 
}
