/* contactsAndMaps.css */

.contacts-and-maps {
    display: flex;
    justify-content: space-between;
    align-items: start;
    max-width: 900px;
    margin: 0 auto;
    padding: 20px 0;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    max-width: 400px;
  }

  .footer>h2{
    color: #007bff;
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .footer>a{
    font-size: 16px;
    margin-bottom: 20px;
    color: #007bff;
  }
  .footer>a:hover{
    text-decoration: underline;
  }

  .map-container {
    width: 50%;
    border: 1px solid #ccc;
  }
  
  .map-container iframe {
    width: 100%;
    height: 300px;
  }
  

  @media (max-width: 768px) {
    .footer>h2{
      font-size: 20px;
    }
    .footer>a{
      font-size: 8px;
      
    }


  }