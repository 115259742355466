
  .prices-and-info {
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    align-items: start;
    flex-direction: row;
    justify-content: space-between;
    max-width: 900px;
    flex-wrap: nowrap; 
  }
  
  .prices {
    min-height: 300px;
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    max-width: 45%; 
    margin-bottom: 20px; 
  }
  
  .safety-info {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 45%; 
    margin-bottom: 20px; 
  }
  
  .price-item h3 {
    font-size: 20px;
    color: #007bff;
    font-weight: normal;
  }
  
  .price-item p {
    font-size: 16px;
    margin: 8px 0;
  }
  
  .safety-info h2 {
    font-size: 20px;
    color: #007bff;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .safety-info p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .price-header {
    color: #007bff;
    font-size: 25px;
    margin-bottom: 20px;
  }


  @media (max-width: 768px) {
    .prices {
      min-height: 600px;
    }
    .safety-info {
      min-height: 600px;
      
    }


  }



