.promo {
    max-width: 100%;
    background-image: 
    linear-gradient(
        rgba(255, 255, 255, 0), 
        rgba(255, 255, 255, 1)
    ), url('../../img/promo-bg.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 900px;
    display: flex;
    align-items: center;
    justify-content: center; /* Центрируем контент */
    padding: 20px; /* Добавляем отступы для мобильных устройств */
}

.promo-text {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Выравниваем текст по центру */
    align-items: center;
    border-radius: 8px; /* Увеличиваем радиус скругления для лучшего вида */
    padding: 20px;
    min-width: 280px; /* Уменьшаем минимальную ширину для мобильных устройств */
    max-width: 90%; /* Уменьшаем максимальную ширину */
    background: rgba(255, 255, 255, 0.7);
    text-align: center; /* Выравниваем текст по центру */
}

.promo-text > h1 {
    font-size: 20px; /* Уменьшаем размер шрифта для мобильных устройств */
    font-weight: bold;
}

.promo-text > p {
    font-size: 16px; /* Уменьшаем размер шрифта для мобильных устройств */
    font-weight: normal;
}

.promo-text > div > a {
    padding: 10px;
}

/* Медиазапрос для экранов шириной менее 768px */
@media (max-width: 768px) {
    .promo {
        min-height: 600px; /* Уменьшаем минимальную высоту на мобильных устройствах */
    }

    .promo-text {
        min-width: 100%; /* Убираем фиксированную ширину */
        max-width: 100%; /* Убираем максимальную ширину */
        margin: 0; /* Убираем отступ слева */
    }

    .promo-text > h1 {
        font-size: 18px; /* Дополнительно уменьшаем размер шрифта для заголовка */
    }

    .promo-text > p {
        font-size: 14px; /* Дополнительно уменьшаем размер шрифта для текста */
    }
}
