.route-container-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 0;
}

.route-container-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2)),
              url('../../img/ural.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
 
}

.route-header {
  font-size: 36px; 
  font-weight: bold; 
  color: #000; 
  padding: 10px 20px; 
 
}

.routes-container {
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  max-width: 900px;

  position: relative; /* Ensure that arrow buttons are correctly positioned */
}

.route-card {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 400px;
  height: 550px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
 
}

.route-image {
  border-radius: 4px;
  height: 300px;
  width: 300px;
}

.route-title {
  font-size: 18px;
  font-weight: bold;
}

.route-description, .route-complexity {
  font-size: 16px;
  margin: 16px 0;
}

.arrow-button_route {
  background-color: transparent;
  border: none;
  color: #007bff;
  font-size: 36px;
  cursor: pointer;
  transition: color 0.3s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.arrow-button_route:first-of-type {
  left: -50px; 
}

.arrow-button_route:last-of-type {
  right: -50px; 
}

.arrow-button_route:hover {
  color: #002d5c;
}

/* Медиазапрос для экранов шириной менее 768px */
@media (max-width: 768px) {
  .routes-container {
    gap: 0; /* Уменьшаем отступы между маршрутами */
    justify-content: center;
  }

  .route-card {
    width: 100%; /* Заставляем карточку занимать всю ширину */
    max-width: 400px; /* Максимальная ширина карточки */
  }

  /* Прячем одну из карточек, чтобы оставалась только одна */
  .routes-container .route-card:nth-child(2) {
    display: none;
  }

  .arrow-button_route:first-of-type {
    left: 0%; 
  }
  
  .arrow-button_route:last-of-type {
    right: 0%; 
  }
  
 
}
