/* Основные стили */
.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1;
}

.header-logo > img {
    max-height: 100px;
    max-width: 350px;
}

.burger-menu {
    display: none;
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}

/* Стили для попап-меню */
.popup-menu {
    position: fixed;
    top: 0;
    right: -500px;
    width: 250px;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0,0,0,0.5);
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    transition: right 0.3s ease;
    
}

.popup-menu.open {
    right: 0;
}

.close-menu {
    align-self: flex-end;
    font-size: 30px;
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 20px;
}

.header-link-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    
}

.header-link-list > li {
    margin: 15px 0;
    font-size: 18px;
    font-weight: 600;
    
}

.header-link-list > li > a {
    text-decoration: none;
    color: black;
    
}

.header-link-list > li > a:hover {
    text-decoration: underline;
}

.header-contact {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact-icon {
    max-height: 60px;
    max-width: 60px;
    padding: 5px;
    margin-bottom: 10px;
}

.contact-icon:hover {
    border: 1px solid #0056b3;
}

/* Медиазапрос для экранов шириной менее 768px */
@media (max-width: 768px) {
    .burger-menu {
        display: block;
        justify-self: self-end;
        
    }

    .header-contact,
    .header-link-list {
        display: none;
    }

    .popup-menu.open .header-link-list,
    .popup-menu.open .header-contact {
        display: flex;
    }
}

/* Стили для экранов больше 768px */
@media (min-width: 769px) {
    .burger-menu {
        display: none;
    }

    .desktop-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-width: 700px;
    }

    .header-contact {
        display: flex;
        flex-direction: row;
        gap: 20px;
        
    }

    .header-link-list {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: center;
        gap: 20px;
        padding: 0 20px;
    }

    .popup-menu {
        display: none; /* Скрываем попап-меню для больших экранов */
    }
    .header-logo > img {
        max-height: 80px;
        max-width: 320px;
    }
}
